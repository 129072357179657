<template>
  <MainLayout>
    <template v-slot:default>
      <TableFilters :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            Всего: {{ sourceObject.count }}
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4 "
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
        <template v-if="role != 31" v-slot:add-button>
          <router-link to="createUser" class="btn btn-add btn_color_green">
            Добавить пользователя
          </router-link>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsers from "@/helpers/tableCols/User";
import moment from "moment/moment";
export default {
  name: "UsersPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.usersKindergarten.filter(
        el => el.userRole.includes(this.$store.getters.getCurrentRole) // && el.type !== "groups" //497
      ),
      objectId: null // id объекта ДС
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getOrganizationUsersList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },
    organizationId() {
      return this.$store.getters.getCurrentOrganization;
    },
    role() {
      return this.$store.getters.getCurrentRole;
    },
    toPath() {
      // return [21, 31].includes(this.role) ? "parentInfos" : "users";
      return "users";
    },
    header() {
      let preHeader = tableColsUsers.filter(el => {
        return (
          el.userRole.includes(this.$store.getters.getCurrentRole) &&
          el.text != "ФИО"
        );
      });
      preHeader.$insert(0, {
        text: "Регистрация",
        value: "is_phone_confirmed",
        renderFunction: value =>
          `<p style='
        ${
          value.is_phone_confirmed === true
            ? "color: #95C23D!important;"
            : "color: #EB5C6D!important;"
        }'
      >${
        value.is_phone_confirmed === true && value.registered_at != null
          ? moment(value.registered_at * 1000).format("DD.MM.YYYY")
          : "Нет"
      }</p>`,
        userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
        sort: true
      });

      preHeader.$insert(4, {
        text: "Карта",
        value: "card_code",
        renderFunction: value => {
          return value.card != null
            ? ` <span>${
                value.card.code
                  ? value?.card.code
                  : value.card.is_awaited
                  ? "Требуется ввод карты "
                  : "Не оплачено"
              }</span>`
            : `<div style="display:relative"><span style="color: #EB5C6D!important;">Не оплачено</span></div>`;
        },
        userRole: [21, 31, 60, 70]
      });

      preHeader.$insert(5, {
        text: "Приложение",
        renderFunction: value => {
          let access = value.actual_accesses; // выбранные группы
          if (access.length >= 2) {
            for (let i = 0; i < access.length; i++) {
              if (access[i].access_type == "app") {
                access = [access[i]];
              }
            }
          }

          if (access && access.length > 0) {
            return access.reduce((res, access) => {
              if (access.access_by == "paid" && access.access_type == "app") {
                res += `<div  style="color: #95C23D!important;">${moment(
                  access.date_end * 1000
                ).format("DD.MM.YYYY")}</div>`;
                return String(res);
              } else if (
                access.access_by == "trial" &&
                access.access_type == "app"
              ) {
                res += `<div style="color: #95C23D!important;">Пробный, ${moment(
                  access.date_end * 1000
                ).format("DD.MM.YYYY")}</div>`;
                return res;
              } else if (
                access.access_by == "unpaid" &&
                access.access_type == "app"
              ) {
                res += `<div style="color: #95C23D!important;">Бесплатный доступ</div>`;
                return res;
              } else {
                return `<div><span style="color: #EB5C6D!important">Отсутствует</span></div>`;
              }
            }, "");
          } else {
            return `<div><span style="color: #EB5C6D!important">Отсутствует</span></div>`;
          }
        },
        userRole: [21, 31, 60, 70]
      });

      return preHeader;
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      if (this.role != 31) {
        this.$store
          .dispatch("downloadObjectsUsersList", this.header)
          .finally(() => {
            this.loadingBtn = false;
          });
      } else {
        this.$store
          .dispatch("downloadkindergartenUsersList", this.header)
          .finally(() => {
            this.loadingBtn = false;
          });
      }
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    if (this.$route.query) {
      this.$router.replace({ query: null });
    }
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, groups__organization: this.urlQuery.id },
      id: this.organizationId
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС
      .then(res => {
        const orgName = this.$store.getters.getDealerInfo.results?.name;
        this.objectId =
          res.results.filter(el => el?.name == orgName)[0]?.id ||
          res.results[0].id ||
          this.$store.getOrganizationObjectsList.results[0].name;
      })
      .then(() => {
        this.$store.dispatch("fetchKindergartensUsersList").finally(() => {
          this.isDateLoaded = false;
        });
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.isDateLoaded = true;
      this.$store.commit("setSearchQuery", {
        id: this.organizationId,
        query: { ...to.query, groups__organization: this.organizationId }
      });
      this.$store.dispatch("fetchKindergartensUsersList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
